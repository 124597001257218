import styled from "styled-components";

const ModalIcon = ({ content }: any) => {
  // const iconMapping = {
  //   성공: icons.alert.check[48],
  //   실패: icons.alert.error[48],
  //   안내: icons.alert.notice[48],
  // };

  const isObjectContent = typeof content === "object";

  if (!content) return null;

  return (
    <S.Container>
      {isObjectContent ? (
        content
      ) : (
        <div
          style={{
            width: "48px",
            height: "48px",
            border: "1px solid gray",
          }}
        />
        // <Image
        //   src={iconMapping[content]}
        //   width={48}
        //   height={48}
        //   alt={content}
        // />
      )}
    </S.Container>
  );
};

export default ModalIcon;

const S = {
  Container: styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    paddingTop: "8px",
    paddingBottom: "12px",
  })),
};
