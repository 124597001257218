import SnackBar from "_components/SnackBar";
import { SnackBarProps } from "_components/SnackBar/types";
import { useSnackbar } from "notistack";

/**
 * 스낵바을 생성하는 훅
 *
 * @param props.text - 스낵바 내용(필수)
 * @param props.variant - 스낵바 variant(선택) 기본값은 "default"
 * @example
 * const { open: (text, variant) => {}, close } = useSnackbar();
 */
const useSnackBar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    open: ({ text, variant = "default" }: SnackBarProps) => {
      enqueueSnackbar(<SnackBar text={text} />, { variant: variant });
    },
    close: closeSnackbar,
  };
};

export default useSnackBar;
