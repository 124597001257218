import typography from "_styles/typography";
import styled from "styled-components";

import { SnackBarProps } from "./types";

const SnackBar = (props: SnackBarProps) => {
  const { text } = props;
  return (
    <S.Container>
      <S.Text>{text}</S.Text>
    </S.Container>
  );
};

const S = {
  Container: styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "auto !important",
  })),
  Text: styled("label")(() => ({
    ...typography["body.200_sb"],
    lineHeight: "19px",
  })),
};

export default SnackBar;
