/* eslint-disable no-unused-vars */
import { MouseEvent } from "react";
import styled from "styled-components";

interface BackDropProps {
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const BackDrop = (props: BackDropProps) => {
  const { onClick } = props;
  return <S.BackDrop onClick={onClick} />;
};
export default BackDrop;

const S = {
  BackDrop: styled("div")({
    width: "100vw",
    height: "100vh",
    position: "absolute",
    background: "rgba(31, 34, 41, 0.2)",
    zIndex: 1,
  }),
};
