/* eslint-disable no-unused-vars */
import { useState } from "react";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import colorToken from "_styles/colorToken";
import typography from "_styles/typography";
import styled from "styled-components";

const corsConfig = `[
  {
    "AllowedHeaders": [
      "*"
    ],
    "AllowedMethods": [
      "GET",
      "HEAD"
    ],
    "AllowedOrigins": [
      "*.aperia.ai.com"
    ],
    "ExposeHeaders": [],
    "MaxAgeSeconds": 3000
  }
]`;

const CorsModal = (props) => {
  const { close, onConfirm, policy, title, description } = props;
  const [isCopied, setIsCopied] = useState(false);

  const handleConfirm = () => {
    // onConfirm();
    close();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(corsConfig).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <S.ClauseModal>
      <S.Title>{title}</S.Title>

      <S.Description>{description}</S.Description>

      <S.Contents>
        <pre>{policy}</pre>
      </S.Contents>

      <S.Button>
        <Button
          variant={isCopied ? "line" : "grayline"}
          size="sm"
          width="100px"
          onClick={handleCopy}
          style={{
            background: "var(--white)",
            width: "fit-content",
            minWidth: "60px",
          }}
        >
          <Typography
            variant="body.100"
            color={isCopied ? "primary_main" : "text_10"}
          >
            {isCopied ? "복사됨" : "복사"}
          </Typography>
        </Button>
        <Button
          variant="default"
          size="sm"
          width="100px"
          onClick={handleConfirm}
          style={{ width: "fit-content", minWidth: "60px" }}
        >
          <Typography variant="body.100" color="text_60">
            확인
          </Typography>
        </Button>
      </S.Button>
    </S.ClauseModal>
  );
};

const S = {
  ClauseModal: styled("div")(() => ({
    width: "536px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "28px",
    backgroundColor: "var(--white)",
    borderRadius: "16px",
  })),
  Title: styled("span")(() => ({
    ...typography["body.200_sb"],
    color: colorToken.text_5,
    marginBottom: "8px",
  })),
  Description: styled("p")(() => ({
    ...typography["body.200"],
    color: colorToken.text_10,
    marginBottom: "16px",
  })),
  Contents: styled("div")<any>(() => ({
    padding: "20px",
    background: "var(--primary_main_5dp)",
    ...typography["body.300"],
    color: colorToken.text_10,
    whiteSpace: "pre-wrap",
    overflowY: "auto" as const,
    // wordBreak: "break-all" as const,
    // wordWrap: "break-word" as const,
    // maxHeight: "200px",
    userSelect: "text", // 텍스트 선택 허용
    cursor: "text", // 텍스트 커서로 변경
    WebkitUserSelect: "text", // 웹킷 기반 브라우저 지원
    MozUserSelect: "text", // 파이어폭스 지원
    msUserSelect: "text", // IE/엣지 지원
  })),
  Button: styled("div")(() => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  })),
};

export default CorsModal;
