"use client";
import { useContext } from "react";
import colorToken from "_styles/colorToken";
import typography from "_styles/typography";
import styled from "styled-components";

import RadioContext from "./RadioProvider";
import { IRadioContext, RadioItemProps, RadioRootProps } from "./types";

/**
 * 
 * @param label - 타이틀 라벨
 * @param value - 값
 * @param onChange
 * @example 
 *  <Radio.Root value={value} onChange={handleSelect}>
      <Radio.Item value="EMAIL">이메일</Radio.Item>
      <Radio.Item value="PHONE">전화</Radio.Item>
      <Radio.Item value="FAX">팩스</Radio.Item>
      <Radio.Item value="MAIL" disabled>
        우편
      </Radio.Item>
    </Radio.Root>
 */
const RadioRoot = ({
  label,
  children,
  direction = "row",
  gap,
  ...restProps
}: RadioRootProps) => {
  return (
    <S.Root>
      {label && <legend>{label}</legend>}

      <RadioContext.Provider value={restProps}>
        <S.Group
          direction={direction}
          style={{
            gap: gap,
          }}
        >
          {children}
        </S.Group>
      </RadioContext.Provider>
    </S.Root>
  );
};

RadioRoot.displayName = "Radio.Root";

const RadioItem = ({
  children,
  value,
  name,
  defaultChecked,
  disabled,
}: RadioItemProps) => {
  const group = useContext<IRadioContext>(RadioContext);

  return (
    <S.Item disabled={disabled}>
      <input
        type="radio"
        value={value}
        name={name}
        defaultChecked={defaultChecked}
        disabled={disabled}
        checked={group.value !== undefined ? value === group.value : undefined}
        onChange={(e) => group.onChange && group.onChange(e.target.value)}
      />
      {children}
    </S.Item>
  );
};

RadioItem.displayName = "Radio.Item";

const S = {
  Root: styled("fieldset")(() => ({
    display: "flex",
  })),
  Group: styled("div")(({ direction }: Pick<RadioRootProps, "direction">) => ({
    display: "flex",
    flexDirection: direction,
    gap: "4px",
  })),
  Item: styled("label")(({ disabled }: Pick<RadioItemProps, "disabled">) => ({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: disabled && "not-allowed",
    ...typography["body.300"],
    color: colorToken.text_10,
    userSelect: "none" as const,

    input: {
      margin: "0 0 3px 0",
    },
  })),
};

export { RadioRoot as Root, RadioItem as Item };
