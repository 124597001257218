import { useState } from "react";
import Input from "_components/Input/Input";
import Textarea from "_components/Textarea";
import styled from "styled-components";

import ModalButton from "../Common/ModalButton";
import ModalContents from "../Common/ModalContents";

const InfoEditModal = (props) => {
  const { close, onConfirm, placeholder } = props;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescChange = (e) => {
    setDesc(e.target.value);
  };

  return (
    <S.Modal>
      <Input
        label="이름"
        // required
        style={{ marginBottom: "28px" }}
        value={name}
        onChange={handleNameChange}
        placeholder={placeholder}
      />

      <Textarea
        label="설명(optional)"
        style={{ height: "128px" }}
        maxLength={100}
        value={desc}
        onChange={handleDescChange}
      />
      <S.Button>
        <ModalButton
          onConfirm={() => onConfirm(name, desc)}
          onCancel={close}
          cancelText="취소"
          confirmText="저장"
        />
      </S.Button>
    </S.Modal>
  );
};

export default InfoEditModal;

const S = {
  Modal: styled.div({
    width: "554px",
    padding: "20px",
  }),
  Button: styled.div({
    marginTop: "28px",
  }),
};
