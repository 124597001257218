import React from "react";
import { Button } from "_components/Button";
import Typography from "_components/Typography";
import styled from "styled-components";

export const TermModal: React.FC<any> = ({
  close,
  currentTerm,
  handleAgree,
  isLastTerm,
}) => {
  return (
    <S.Container>
      <div style={{ marginBottom: "24px" }}>
        <Typography variant="title.200_sb" color={"text_5"}>
          {currentTerm?.name}
        </Typography>
      </div>
      <S.TermText>
        <Typography variant="caption.200" color={"text_10"}>
          {currentTerm?.content || "약관 내용..."}
        </Typography>
      </S.TermText>
      <S.ButtonContainer>
        <Button variant="grayline" size="sm" onClick={close}>
          취소
        </Button>
        <Button variant="default" size="sm" onClick={handleAgree}>
          {isLastTerm ? "동의" : "동의 후 다음"}
        </Button>
      </S.ButtonContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    padding: 40px;
    width: 480px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  `,
  TermText: styled.div`
    background-color: var(--bg-default, #fafafa);
    white-space: pre-wrap;
    padding: 16px;
    height: 40vh;
    overflow-y: auto;
  `,
  ButtonContainer: styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    button {
      width: 120px;
    }
  `,
};
