import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import BackDrop from "../BackDrop";

import BucketConnectionModal from "./Modals/BucketConnectionModal";
import ClauseModal from "./Modals/ClauseModal";
import ConsultingModal from "./Modals/ConsultingModal";
import CorsModal from "./Modals/CorsModal";
import DefaultModal from "./Modals/DefaultModal";
import FileSelectModal from "./Modals/FileSelectModal";
import FileUploadModal from "./Modals/FileUploadModal";
import InfoEditModal from "./Modals/InfoEditModal";
import InputModal from "./Modals/InputModal";
import { TermModal } from "./Modals/TermModal";
import UnlinkModal from "./Modals/UnlinkModal";
import { ModalAppearanceType, ModalOwnProps } from "./types";

const MODAL_APPEARANCE: ModalAppearanceType = {
  default: <DefaultModal />,
  bucketConnection: <BucketConnectionModal />,
  clause: <ClauseModal />,
  fileUpload: <FileUploadModal />,
  fileSelect: <FileSelectModal />,
  input: <InputModal />,
  termsAgreement: <TermModal />,
  cors: <CorsModal />,
  infoEdit: <InfoEditModal />,
  unlink: <UnlinkModal />,
  consulting: <ConsultingModal />,
};

const Modal = (props: ModalOwnProps) => {
  const { type, close, ...otherProps } = props;

  const modalRef = useRef<HTMLDivElement>(null);

  const modalElement = MODAL_APPEARANCE[type];

  useEffect(() => {
    const scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollBarWidth}px`;

    return () => {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0px";
    };
  }, []);

  return (
    <S.ModalContainer>
      <BackDrop onClick={close} />

      <S.Modal ref={modalRef}>
        <div>{React.cloneElement(modalElement, { close, ...otherProps })}</div>
      </S.Modal>
    </S.ModalContainer>
  );
};

export default Modal;

const S = {
  ModalContainer: styled("div")({
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  Modal: styled("div")(() => ({
    position: "absolute" as const,
    border: "1px solid",
    userSelect: "none" as const,
    borderColor: "var(--line_10)",
    borderRadius: "8px",
    backgroundColor: "white",
    zIndex: 999,
  })),
};
