import styled from "styled-components";

import ModalButton from "../Common/ModalButton";
import ModalContents from "../Common/ModalContents";
import ModalIcon from "../Common/ModalIcon";

const DefaultModal = (props) => {
  const { style, close, message, onConfirm } = props;

  return (
    <S.DefaultModal style={style}>
      <div>
        <ModalIcon content={message?.icon} />

        <ModalContents
          title={message?.title}
          description={message?.description}
        />
      </div>

      <ModalButton
        onConfirm={onConfirm}
        onCancel={close}
        confirmText={message?.confirmText}
        cancelText={message?.cancelText}
      />
    </S.DefaultModal>
  );
};

const S = {
  DefaultModal: styled.div`
    padding: 32px 0;
  `,
};

export default DefaultModal;
