import React, { useState } from "react";
import { Button } from "_components/Button";
import { Radio } from "_components/Radio";
import Typography from "_components/Typography";
import useSnackBar from "_hooks/SnackBar/useSnackBar";
import styled from "styled-components";

import External from "./External";
import Local from "./Local";

const FileUploadModal = (props) => {
  const { close, detail, onConfirm } = props;

  const { open } = useSnackBar();
  const [type, setType] = useState("external");

  const onSelectType = (type) => {
    setType(type);
  };

  const handleSubmit = (form) => {
    // TODO : 업로드 버튼 활성화 조건 추가

    form.preventDefault();

    // console.log(form);

    open({
      variant: "success",
      text: "파일을 성공적으로 업로드했습니다!",
    });

    close();
  };

  return (
    <S.Container>
      <Typography
        color="text_5"
        variant="subtitle.200_sb"
        style={{ marginBottom: "16px" }}
      >
        업로드 유형
      </Typography>

      <S.UploadType>
        <Radio.Root value={type} gap={20} onChange={onSelectType}>
          <Radio.Item value="external">외부 스토리지</Radio.Item>
          <Radio.Item value="local">로컬 파일</Radio.Item>
        </Radio.Root>
      </S.UploadType>

      {type === "external" && <External />}
      {type === "local" && <Local />}

      <S.Submit>
        <Button variant="gray2" size="sm" width="100px" onClick={close}>
          취소
        </Button>
        <Button
          variant="default"
          size="sm"
          width="100px"
          type="submit"
          onClick={(form) => handleSubmit(form)}
        >
          업로드
        </Button>
      </S.Submit>
    </S.Container>
  );
};

const S = {
  Container: styled.form`
    display: flex;
    flex-direction: column;
    width: 760px;
    padding: 40px;
  `,
  UploadType: styled.div`
    margin-bottom: 20px;
  `,
  Buttons: styled.div`
    display: flex;
    gap: 12px;
    margin-bottom: 28px;
  `,
  Submit: styled.div`
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  `,
};

export default FileUploadModal;
