import { Button } from "_components/Button";
import styled from "styled-components";

import { ModalButtonGroupProps } from "./types";

const ModalButton = ({
  onConfirm,
  onCancel,
  confirmText = "확인",
  cancelText = "취소",
}: ModalButtonGroupProps) => {
  const buttonType = onConfirm ? "two" : "one";

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    onCancel();
  };
  return (
    <S.Container>
      {buttonType === "one" ? (
        <Button width="168px" size="md" variant="default" onClick={onCancel}>
          {confirmText}
        </Button>
      ) : (
        <>
          <Button width="168px" size="md" variant="gray2" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button
            width="168px"
            size="md"
            variant="default"
            onClick={handleConfirm}
          >
            {confirmText}
          </Button>
        </>
      )}
    </S.Container>
  );
};

export default ModalButton;

const S = {
  Container: styled("div")(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "16px",
  })),
};
