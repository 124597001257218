import { Button } from "_components/Button";
import colorToken from "_styles/colorToken";
import typography from "_styles/typography";
import styled from "styled-components";

const ClauseModal = (props) => {
  const { close, detail, onConfirm } = props;

  const handleConirm = () => {
    onConfirm();
    close();
  };

  return (
    <S.ClauseModal>
      <S.Title>서비스 이용약관</S.Title>

      <S.Contents>
        <text>{detail}</text>
      </S.Contents>

      <S.Button>
        <Button variant="gray2" size="sm" width="100px" onClick={close}>
          취소
        </Button>
        <Button
          variant="default"
          size="sm"
          width="100px"
          onClick={handleConirm}
        >
          동의
        </Button>
      </S.Button>
    </S.ClauseModal>
  );
};

const S = {
  ClauseModal: styled("div")(() => ({
    width: "480px",
    display: "flex",
    flexDirection: "column" as const,
    gap: "24px",
  })),
  Title: styled("span")(() => ({
    ...typography["title.200_sb"],
    color: colorToken.text_5,
  })),
  Contents: styled("div")(() => ({
    padding: 12,
    background: "var(--background_default)",
    ...typography["caption.200"],
    color: colorToken.text_10,
    whiteSpace: "pre-line",
    wordBreak: "break-all" as const,
    wordWrap: "break-word" as const,
    maxHeight: "500px",
    overflowY: "scroll" as const,
  })),
  Button: styled("div")(() => ({
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px",
  })),
};

export default ClauseModal;
