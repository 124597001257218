import React from "react";
import CheckIcon from "_assets/icons/ic_check_default_white.svg";
import { Button } from "_components/Button";
import SVG from "_components/SVG/Svg";
import Typography from "_components/Typography";
import styled from "styled-components";

const consultingObject: any = {
  default: {
    manager: "Leo (ATAD Corp. 대표 이사)",
    date: "2024년 08월 08일",
    time: "14:00, 14:30",
    meetingMethod: "오프라인 대면 미팅",
    status: "담당자가 일정을 확인중 이에요",
  },
  success: {
    manager: "Leo (ATAD Corp. 대표 이사)",
    date: "2024년 08월 08일",
    time: "14:00, 14:30",
    meetingMethod: "오프라인 대면 미팅",
    status: null,
  },
};

const variantObject = {
  default: {
    title: "컨설팅 일정 정보",
    description: "확정 전엔 일정을 변경할 수 있어요.",
    button: {
      cancel: "일정 변경하기",
      confirm: "확인",
    },
  },
  success: {
    title: "컨설팅 일정 정보",
    description: "확인 버튼을 누르면 일정이 요청 돼요.",
    button: {
      cancel: "취소",
      confirm: "확인",
    },
  },
};

const ConsultingInfoModal = (props) => {
  const { close, variant, onConfirm, onCancel } = props;

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    close();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    close();
  };

  return (
    <S.ModalContainer>
      <S.Header>
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <SVG
            src={CheckIcon}
            width={24}
            height={24}
            stroke="#2E90FA"
            strokeWidth={2}
          />

          <Typography variant="subtitle.100_sb" color="text_5">
            {variantObject[variant].title}
          </Typography>
        </div>
        <Typography variant="body.200" color="text_10">
          {variantObject[variant].description}
        </Typography>
      </S.Header>

      <S.Divider />

      <S.InfoContainer>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            담당자
          </Typography>
          <Typography variant="body.200" color="text_5">
            {consultingObject[variant].manager}
          </Typography>
        </S.InfoRow>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            컨설팅 날짜
          </Typography>
          <Typography variant="body.200" color="text_5">
            {consultingObject[variant].date}
          </Typography>
        </S.InfoRow>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            컨설팅 시간
          </Typography>
          <Typography variant="body.200" color="text_5">
            {consultingObject[variant].time}
          </Typography>
        </S.InfoRow>
        <S.InfoRow>
          <Typography variant="body.200" color="text_10">
            미팅 방법
          </Typography>
          <Typography variant="body.200" color="text_5">
            {consultingObject[variant].meetingMethod}
          </Typography>
        </S.InfoRow>
        {consultingObject[variant].status && (
          <S.InfoRow>
            <Typography variant="body.200" color="text_10">
              상태
            </Typography>
            <Typography variant="body.200" color="text_5">
              {consultingObject[variant].status}
            </Typography>
          </S.InfoRow>
        )}
      </S.InfoContainer>

      <S.ButtonContainer>
        <Button
          onClick={handleCancel}
          variant="grayline"
          size="md"
          width="100%"
        >
          <Typography variant="body.200" color="text_10">
            {variantObject[variant].button.cancel}
          </Typography>
        </Button>
        <Button
          onClick={handleConfirm}
          variant="default"
          size="md"
          width="100%"
        >
          <Typography variant="body.200" color="white">
            {variantObject[variant].button.confirm}
          </Typography>
        </Button>
      </S.ButtonContainer>
    </S.ModalContainer>
  );
};

export default ConsultingInfoModal;

const S = {
  ModalContainer: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 468px;

    width: 468px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 24px;
    border-radius: 8px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  Header: styled.div`
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Title: styled.h2`
    color: var(--text_2);
  `,
  Divider: styled.hr`
    height: 1px;
    background-color: var(--line_10);
    margin: 20px 0;
    border: none;
  `,
  InfoContainer: styled.div`
    background-color: var(--card_up);
    border-radius: 8px;
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 28px;
  `,
  InfoRow: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  InfoText: styled.p`
    color: var(--text_5);
  `,
  ButtonContainer: styled.div`
    display: flex;
    gap: 16px;
  `,
};
