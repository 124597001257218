import { Button } from "_components/Button";
import Typography from "_components/Typography";
import Image from "next/image";
import styled from "styled-components";

const EmptyExternel = () => {
  return (
    <S.EmptyExternel>
      <Image src="" alt="" width={60} height={60} />

      <Typography
        variant="body.200"
        color="text_10"
        style={{ textAlign: "center" }}
      >
        현재 연동된 외부 스토리지가 없습니다.
        <br />
        연동 후 이용해주세요.
      </Typography>

      <Button variant="gray1" size="xs" width="182px">
        연동하기
      </Button>
    </S.EmptyExternel>
  );
};

const S = {
  EmptyExternel: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    height: 428px;
  `,
};

export default EmptyExternel;
