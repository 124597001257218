import { useState } from "react";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import styled from "styled-components";

import ModalButton from "../Common/ModalButton";
import ModalContents from "../Common/ModalContents";

const UnlinkModal = (props) => {
  const { close, title, onConfirm, placeholder } = props;
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <S.Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <ModalContents title="해당 버킷을 연동 해제하시겠습니까?" />

        <Typography variant="body.100" color="text_10">
          animal
        </Typography>
      </div>

      <Input
        style={{ marginBottom: "12px" }}
        value={inputValue}
        onChange={handleInputChange}
        placeholder="삭제를 위해 버킷 이름을 다시 입력해 주세요."
      />

      <S.Caption>
        <Typography variant="caption.100" color="primary_main">
          * 버킷 연동을 해제하면, 파일이 영구적으로 삭제되며 다시 복구할 수
          없습니다.
        </Typography>
      </S.Caption>

      <ModalButton
        onConfirm={() => onConfirm(inputValue)}
        onCancel={close}
        cancelText="취소"
        confirmText="확인"
      />
    </S.Modal>
  );
};

export default UnlinkModal;

const S = {
  Modal: styled.div({
    width: "564px",
    padding: "32px",
  }),
  Caption: styled.div({
    margin: "12px 0px 28px 0px",
  }),
};
