import React from "react";
import Typography from "_components/Typography";
import { icons } from "_styles/shared/icons";
import Image from "next/image";
import styled from "styled-components";

const items = [
  {
    title: "농촌진흥청_창업을 위한 식용 곤충요리_20191213",
    size: "3.5MB",
  },
  {
    title: "농림축산식품부_스마트팜 데이터 마트 노지 빅데이터 제공 서비스...",
    size: "3.5MB",
  },
  {
    title: "농림축산식품부_스마트팜 데이터 1",
    size: "3.5MB",
  },
  {
    title: "농림축산식품부_스마트팜 데이터 2",
    size: "3.5MB",
  },
  {
    title: "농촌진흥청_창업을 위한 식용 곤충요리_20191213",
    size: "3.5MB",
  },
];

const Local = () => {
  return (
    <S.Local>
      <S.Upload>
        <Image src="" alt="" width={24} height={24} />
        <Typography variant="body.200" color="text_10">
          클릭 또는 파일을 끌어 올리세요.
        </Typography>
      </S.Upload>

      <S.Items>
        {items.map((item, i) => (
          <S.Item key={i}>
            <Image src={icons.check.main_20} alt="" width={16} height={16} />
            <Typography variant="caption.100" color="text_5">
              {item.title}
            </Typography>
            <Typography variant="caption.100" color="text_5">
              {item.size}
            </Typography>

            <Image
              src={icons.close.bgray400_16}
              alt=""
              width={24}
              height={24}
              style={{
                position: "absolute",
                right: 0,
                padding: "12px",
                cursor: "pointer",
              }}
            />
          </S.Item>
        ))}
      </S.Items>
    </S.Local>
  );
};

const S = {
  Local: styled.div`
    //
  `,
  Upload: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 276px;
    border: 1.5px dashed var(--line_60);
    border-radius: 12px;
    margin-bottom: 20px;
  `,
  Items: styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
    max-height: 200px;
    overflow-y: auto;
    position: relative;
  `,
  Item: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px;
    background: var(--background_default);
    border-radius: 8px;
  `,
};

export default Local;
