import { Controller, useForm } from "react-hook-form";
import { Button } from "_components/Button";
import Input from "_components/Input/Input";
import Typography from "_components/Typography";
import typography from "_styles/typography";
import styled from "styled-components";

interface FormData {
  bucketName: string;
  region: string;
  accessKey: string;
  secretAccessKey: string;
  folderPath: string;
}

const BucketConnectionModal = (props) => {
  const { close, onConfirm } = props;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: "onChange",
  });

  const onSubmit = async (data: FormData) => {
    onConfirm(data);
  };

  const handleAWSLinkClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    window.open(
      "https://console.aws.amazon.com/s3/",
      "_blank",
      "noopener,noreferrer",
    );
  };

  return (
    <S.Container>
      <S.Title>Add new account</S.Title>

      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.SubTitle>연결 계정 인증</S.SubTitle>

        <Controller
          name="bucketName"
          control={control}
          rules={{ required: "Bucket Name은 필수입니다." }}
          render={({ field }) => (
            <Input
              label={
                <Typography variant="body.200" color="text_10">
                  Bucket Name *
                </Typography>
              }
              subLabel={
                <Typography variant="body.200" color="text_10">
                  <span
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleAWSLinkClick}
                    role="link"
                    tabIndex={0}
                    aria-label="AWS S3 콘솔 페이지로 이동 (새 탭에서 열림)"
                  >
                    버킷
                  </span>
                  을 눌러 확인할 수 있습니다.
                </Typography>
              }
              required
              fullWidth
              errorMessage={errors.bucketName?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="region"
          control={control}
          rules={{ required: "Region은 필수입니다." }}
          render={({ field }) => (
            <Input
              label={
                <Typography variant="body.200" color="text_10">
                  Region *
                </Typography>
              }
              required
              fullWidth
              errorMessage={errors.bucketName?.message}
              {...field}
            />
          )}
        />

        <S.TwoColumnLayout>
          <S.Column>
            <Controller
              name="accessKey"
              control={control}
              rules={{ required: "Access Key는 필수입니다." }}
              render={({ field }) => (
                <Input
                  label={
                    <Typography variant="body.200" color="text_10">
                      Access Key *
                    </Typography>
                  }
                  required
                  fullWidth
                  errorMessage={errors.accessKey?.message}
                  {...field}
                />
              )}
            />
          </S.Column>
          <S.Column>
            <Controller
              name="secretAccessKey"
              control={control}
              rules={{ required: "Secret Access Key는 필수입니다." }}
              render={({ field }) => (
                <Input
                  label={
                    <Typography variant="body.200" color="text_10">
                      Secret Access Key *
                    </Typography>
                  }
                  required
                  fullWidth
                  errorMessage={errors.secretAccessKey?.message}
                  {...field}
                />
              )}
            />
          </S.Column>
        </S.TwoColumnLayout>

        <Controller
          name="folderPath"
          control={control}
          render={({ field }) => (
            <Input
              label={
                <Typography variant="body.200" color="text_10">
                  Folder Path(optional)
                </Typography>
              }
              placeholder="특정 폴더를 가져오려면 폴더명을 입력해주세요."
              fullWidth
              {...field}
            />
          )}
        />

        <S.ButtonWrapper>
          <Button
            width="120px"
            size="md"
            variant="gray2"
            onClick={close}
            type="button"
          >
            취소
          </Button>
          <Button
            width="120px"
            size="md"
            variant="default"
            type="submit"
            disabled={!isValid}
          >
            연동
          </Button>
        </S.ButtonWrapper>
      </S.Form>
    </S.Container>
  );
};

const S = {
  Form: styled("form")({
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: "20px",
    },
    "& > ${S.SubTitle} + *": {
      marginTop: "-4px", // 20px - 16px = 4px
    },
  }),
  Container: styled("div")({
    width: "560px",
    padding: "40px",
  }),
  Title: styled("span")({
    color: "var(--text_5)",
    ...typography["headline.400"],
    marginBottom: "24px",
    display: "block",
  }),
  SubTitle: styled("span")({
    color: "var(--text_5)",
    ...typography["body.200_sb"],
    marginBottom: "16px",
  }),
  TwoColumnLayout: styled("div")({
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
  }),
  Column: styled("div")({
    flex: "1 0 calc(50% - 10px)",
    maxWidth: "calc(50% - 10px)",
  }),
  ButtonWrapper: styled("div")({
    display: "flex",
    justifyContent: "right",
    columnGap: "12px",
  }),
};

export default BucketConnectionModal;
