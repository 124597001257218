import styled from "styled-components";

import { ModalContentsProps } from "./types";
import Typography from "_components/Typography";

const ModalContents = ({ title, description }: ModalContentsProps) => {
  return (
    <S.Container>
      {title && (
        <Typography variant="subtitle.200_sb" color="text_5" textAlign="center">
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="body.100" color="text_10">
          {description}
        </Typography>
      )}
    </S.Container>
  );
};

export default ModalContents;

const S = {
  Container: styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "4px",
    marginBottom: "24px",
  }),
};
